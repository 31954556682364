import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { Store } from "vuex";
import { App } from "vue";
import { TinyEmitter } from "tiny-emitter";

const AUTHENTICATED_EVENT = 'auth:success'

export { AUTHENTICATED_EVENT }

class AzureLogin {
    private msalInstance: PublicClientApplication|null
    private readonly store: Store<any>
    private $emitter: TinyEmitter

    constructor(
        store: Store<any>,
        $emitter: any
    ) {
        this.store = store
        this.msalInstance = null
        this.$emitter = $emitter
    }

    private getApplication(): PublicClientApplication
    {
        if (!this.msalInstance) {
            this.msalInstance = new PublicClientApplication(
                this.store.state.msalConfig
            )
        }

        return this.msalInstance
    }

    public handle() {
        try {
            this.handleLoginSuccess()
            this.handleRedirect()
        } catch (err) {
            console.log('err', err)
        }
    }

    private handleLoginSuccess()
    {
        const instance = this.getApplication()

        instance.addEventCallback((event: any) => {
            if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
                instance.setActiveAccount(event.payload.account)
            }
        });
    }

    private handleRedirect()
    {
        const instance = this.getApplication()

        instance.handleRedirectPromise().then(() => {
            if (instance.getActiveAccount()) {
                this.$emitter.emit(AUTHENTICATED_EVENT)
                return;
            }

            instance.loginRedirect().catch(err => {
                console.log('err', err)
            })
        }).catch(err => {
            console.log(err);
        });
    }
}

export default {
    install(app: App, options: any) {
        if (!options.store) {
            console.warn('store required')
            return
        }

        const azureLogin = new AzureLogin(
            options.store,
            app.config.globalProperties.$emitter
        )

        azureLogin.handle()
    }
}