<template>
    <label for="contato1" class="form-label">TELEFONE CELULAR / TELÉFONO CELULAR / CELL NUMBER (OPCIONAL):</label>
    <input v-if="maskVl" v-mask="maskVl" type="text" class="form-control" id="cellPhone" v-model="phone" :placeholder="vlPhone"> 
    <input v-else type="text" class="form-control" id="cellPhone" v-model="phone" :placeholder="vlPhone">
</template>
<script>
    import {mask} from 'vue-the-mask'

    export default {

        props: ['maskCellPhone', 'vlPlaceholderCellPhone'],
        name: 'CompCellPhone',
        directives: {mask},

        data() {
            return {                
                vlPhone: '',
                maskVl: '',
                phone: ''
            };
        }, 

        mounted() {
            this.setMasks();
        },   

        methods: {
            setMasks() {
                this.phone = '';
                this.maskVl = '';
                this.maskVl = this.maskCellPhone ? this.maskCellPhone : '';
                this.vlPhone = this.vlPlaceholderCellPhone;
            }
        },      
        watch:{
            phone() { 
                this.$emit('onUpdatePhone', this.phone);
            },
            maskCellPhone() { this.setMasks(); }
        }
    }
</script>