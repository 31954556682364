function s3Url(path: string) {
    return process.env.VUE_APP_S3_URL + '/assets/' + path
}

function localUrl(path: string) {
    return '../../assets/' + path
}

function isDev() {
    return process.env.NODE_ENV === 'development'
}

export function asset_url(path: string) {
    if (!path) {
        return ''
    }

    return isDev() ? localUrl(path) : s3Url(path)
}

export function image_url(path: string) {
    return asset_url('image/' + path)
}