<template>
    <label for="sitesLatam" class="form-label">SITE:</label>
    <select id="sitesLatam" class="form-select" aria-describedby="adressTigre" v-model="selected">
        <option v-for="(item, index) in options" :key="index" >{{item.param}}</option>
    </select>
</template>

<script>
    export default {
        data() {
            return {                
                selected: this.valor
            };
        },
        emits: ['input', 'change'],
        name: 'CompSiteLatam',
        props: ['sitesLatam', 'link'],
        methods: {
            setParam()  {
                this.selected = this.sitesLatam[0];
                return Object.keys(this.sitesLatam).map( k => {
                    let param = this.sitesLatam[k];                    
                    return {param}
                });
            }
        },
        watch:{
            valor() {
              this.selected = this.valor
            },
            selected() {
                this.$emit('input', this.selected)
            },
        },
        created() {            
            this.selected = this.sitesLatam[0];
        },  
        computed: {
            options() {
               return this.setParam();
            }
        }
    }
</script>

   