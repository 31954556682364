<template>
  <div id="signatureBUs">
    <component :is="'style'">
      @import
      url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&display=swap');
    </component>
    <table
      width="420"
      cellpadding="0"
      cellspacing="0"
      border="0"
      style="width: 420px; padding-top: 15px"
    >
      <tbody>
        <tr>
          <td height="10"></td>
        </tr>
        <tr align="left" width="420">
          <td width="120" valign="top" class="cargo-wrapper">
            <a :href="vlLink" target="”_blank”">
              <img width="105" height="100" :src="image" alt="Logo Tigre" />
            </a>
          </td>
          <td width="280" valign="middle" height="100">
            <br />
            <div style="padding-bottom: 5px">
              <strong>
                <span
                  style="
                    font-size: 12px;
                    font-family: Ubuntu, Verdana, Arial, sans-serif;
                    color: #1f3c71;
                  "
                >
                  {{ fieldName }}
                </span>
              </strong>
            </div>
            <div style="padding-bottom: 5px">
              <span
                style="
                  font-size: 12px;
                  font-family: Ubuntu, Verdana, Arial, sans-serif;
                  color: #1f3c71;
                "
              >
                {{ fieldArea }}
              </span>
            </div>
            <div style="padding-bottom: 5px">
              <span
                v-if="homePhone"
                style="
                  font-size: 12px;
                  font-family: Ubuntu, Verdana, Arial, sans-serif;
                  color: #1f3c71;
                "
              >
                {{ homePhone }} |
              </span>
              <a
                :href="vlLink"
                style="
                  font-size: 12px;
                  font-family: Ubuntu, Verdana, Arial, sans-serif;
                  color: #1f3c71;
                "
                target="_blank"
              >
                {{
                  site == "https://www.tigre.com.br/en/export"
                    ? "www.tigre.com.br"
                    : site
                }}
              </a>
            </div>
          </td>
        </tr>
        <tr width="420" valign="middle">
          <td align="left" height="10" colspan="2">
            <span
              style="
                font-family: Ubuntu, Verdana, Arial, sans-serif;
                font-size: 10px;
                color: rgb(60, 158, 212);
                font-weight: bold;
              "
            >
              {{ vlText }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "CompSiganatureBUs",
  data() {
    return {
      imagemBanner: "",
      vlName: "Nome e Sobrenome.",
      vlAreaCargo: "Nome da área ou Cargo.",
      vlEndereco: "",
      vlTeams: "Teams: ",
      vlSite: "",
      vlPhone: "",
      vlCellPhone: "",
    };
  },
  props: [
    "site",
    "fieldName",
    "adress",
    "fieldArea",
    "fieldAdress",
    "fieldTeams",
    "homePhone",
    "cellPhone",
    "vlText",
  ],
  computed: {
    image: {
      get() {
        return "https://prod-sign-tigre.s3.amazonaws.com/assets/image/Logo_Assinatura.png";
      },
    },
    vlLink: {
      get() {
        return this.site == "https://www.tigre.com.br/en/export"
          ? this.site
          : "https://" + this.site;
      },
    },
  },
};
</script>
