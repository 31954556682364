import { createApp } from 'vue';
import { TinyEmitter } from 'tiny-emitter';
import azureStore from './store/azure';
import azureLogin from './plugins/azure_login'
import { asset_url, image_url } from './services/asset'

import App from './App.vue';

const app = createApp(App);

app.config.globalProperties.$emitter = new TinyEmitter()
app.config.globalProperties.$asset_url = asset_url
app.config.globalProperties.$image_url = image_url

app.use(azureStore);
app.use(azureLogin, {store: azureStore});

app.mount('#app');
