<template>
    <div id="signatureBUs">
        <component :is="'style'">
        @import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&display=swap');
        </component>
        <table
            width="420"
            cellpadding="0"
            cellspacing="0"
            border="0"
            style="width: 480px; padding-top:15px;"
        >
        <tbody>
            <tr>
                <td height="10"></td>
            </tr>
            <tr align="left">
                <td valign="top" class="cargo-wrapper">
                    <a href="https://www.tigreusa.com" target=”_blank”>
                        <img width="200" height="130" :src="image" alt="Logo Tigre">
                    </a>
                </td>
                <td  valign="middle" height="130">
                    <br/>
                    <div style="padding-bottom: 5px;">
                        <strong> 
                            <span style="font-size: 12px; font-family: Ubuntu, Verdana, Arial, sans-serif;  color: #1f3c71;"> 
                                {{ !this.name ? vlName : this.name }}
                            </span>
                        </strong>
                    </div>
                    <div style="padding-bottom: 5px;">
                        <span style="font-size: 12px;
                            font-family: Ubuntu, Verdana, Arial, sans-serif;
                            color: #1f3c71;"> 
                            {{ cargoArea }}
                        </span>
                    </div>
                    <div class="">
                        <span style="font-size: 12px;
                            font-family: Ubuntu, Verdana, Arial, sans-serif;
                            color: #1f3c71;"> 
                            {{ fieldHomePhone }}
                        </span>
                    </div>
                    <div style="padding-bottom: 5px;">                       
                        <a href="https://www.tigreusa.com" style="text-decoration: underline; color: #1f3c71; font-size: 13px; font-family: Ubuntu, Verdana, Arial, sans-serif;" target="_blank"> 
                            www.tigreusa.com |
                        </a>
                        <a href="https://www.duraplastics.com" style="text-decoration: underline; color: #1f3c71; font-size: 13px; font-family: Ubuntu, Verdana, Arial, sans-serif;" target="_blank"> 
                            www.duraplastics.com 
                        </a>
                    </div>
                </td>
            </tr>
            <tr align="left" valign="middle" >
                <td height="10"  colspan="2">
                    <span  style="font-family: Ubuntu, Verdana, Arial, sans-serif;
                        font-size: 13px;
                        color: rgb(60, 158, 212);
                        font-weight: bold;"> 
                        {{ vlText }} 
                    </span>
                </td>
            </tr>
        </tbody>
        </table>
    </div>
</template>
<script> 
    export default {
        name: "CompSignatureUSA",
        data() {
            return {
                imagemBanner: "",
                vlName: "Name and full name"            
            };
        },
        props: [
        "bannerImagem",
        "site",
        "link",
        "fieldName",
        "adress",
        "fieldArea",
        "fieldAdress",
        "fieldTeams",
        "cellPhone",
        "homePhone",
        "vlText"
        ],        
        computed: {    
            name: {
                get() {
                return this.fieldName;
                },
            },
            image: {
                get() {
                return this.$image_url("signature/" + this.bannerImagem);
                },
            },
            cargoArea: {
                get() {
                return this.fieldArea;
                },
            },
            adressTigre: {
                get() {
                return this.adress[0];
                },
            },
            teams: {
                get() {
                return this.fieldTeams;
                },
            },
            fieldCellPhone: {
                get() {
                return this.cellPhone;
                },
            },
            fieldHomePhone: {
                get() {
                return this.homePhone ? this.homePhone : '';
                },
            },
        }
    }
</script>
