<template>
  <div class="row">
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="combineImages">
          <div class="row">
            <div class="col-md-6">
              <div class="mb-3">
                <label for="unidade" class="form-label"
                  >UNIDADE / UNIDAD / UNIT:</label
                >
                <select
                  id="unidade"
                  class="form-select"
                  aria-describedby="unidadeTigre"
                  @change="updateData"
                  v-model="currentUnit"
                >
                  <option v-for="(item, index) in units" v-bind:key="index">
                    {{ item }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-md-6">
              <div class="mb-3">
                <label for="nome" class="form-label"
                  >NOME / NOMBRE / NAME:</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="nome"
                  placeholder="Nome e sobrenome"
                  v-on:keyup="updateFieldsName"
                  required
                />
              </div>
            </div>

            <div class="col-md-6">
              <div class="mb-3">
                <label for="areaCargo" class="form-label"
                  >ÁREA OU CARGO / DEPARTAMENTO O CARGO / DEPARTMENT OR JOB
                  POSITION:</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="areaCargo"
                  placeholder="Nome área ou cargo"
                  v-model="cargoArea"
                  v-on:keyup="updateFieldsArea"
                />
              </div>
            </div>

            <div class="col-md-6" v-if="hiddenInput">
              <div class="mb-3">
                <CompCellPhone
                  :maskCellPhone="maskCellPhone"
                  :vlPlaceholderCellPhone="vlPlaceholderCellPhone"
                  @onUpdatePhone="updateFieldsCellPhone"
                />
              </div>
            </div>

            <div class="col-md-6">
              <div class="mb-3">
                <CompHomePhone
                  v-if="hiddenInput"
                  :maskHomePhone="maskHomePhone"
                  v-model:valueHomePhone="form.homePhone"
                  :vlPlaceholderHomePhone="vlPlaceholderHomePhone"
                  :hiddenInput="hiddenInput"
                  :genericInput="genericInput"
                  @onUpdate="updateFieldsHomePhone"
                />

                <CompPhoneGeneric
                  v-if="this.genericInput"
                  :maskHomePhone="maskHomePhone"
                  v-model:valuePhoneGeneric="form.homePhone"
                  :vlPlaceholderHomePhone="vlPlaceholderHomePhone"
                  :hiddenInput="hiddenInput"
                  :genericInput="genericInput"
                  :currentUnit="currentUnit"
                  @onUpdatePhoneGeneric="updateFieldsPhoneGeneric"
                />
              </div>
            </div>

            <div class="col-md-6" v-if="this.hiddenInput">
              <div class="mb-3">
                <CompInputEndereco
                  :adress="adress"
                  v-model:valor="form.adress"
                  @input="onChageAdress"
                />
              </div>
            </div>

            <div class="col-md-6">
              <div class="mb-3">
                <CompInputSite
                  v-if="!isBusLatam"
                  disable
                  :link="link"
                  :site="site"
                  v-model:valueSite="site"
                />
                <CompSiteLatam
                  v-if="isBusLatam"
                  :sitesLatam="sitesLatam"
                  v-model:valueSite="site"
                  @input="onChageSite"
                />
              </div>
            </div>

            <div class="col-md-6" v-if="this.hiddenInput">
              <div class="mb-3">
                <label for="teams" class="form-label">Teams</label>
                <input
                  type="text"
                  class="form-control"
                  id="teams"
                  placeholder="Teams"
                  v-on:keyup="updateFieldsTeams"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <button type="submit" class="btn btn-primary style-btn-click">
              GERAR
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div v-if="qrCode">
    <vue-qrcode
      name="qrcode-canvas"
      ref="qrcode"
      :value="qrCode"
      width="100"
      class="qrcode"
    />
    <canvas
      ref="canvas"
      class="canvas"
      :width="canvasWidth"
      :height="canvasHeight"
    ></canvas>
  </div>
  <div class="row style-padding-Signature">
    <div class="col-xl-3"></div>
    <div class="col-xl-6">
      <CompSignature
        v-if="!assingBUs && !assingUSA"
        :bannerImagem="bannerImagem"
        :site="site"
        :link="link"
        :fieldName="fieldName"
        :adress="adress"
        :fieldArea="fieldArea"
        :fieldAdress="fieldAdress"
        :fieldTeams="fieldTeams"
        :cellPhone="cellPhone"
        :homePhone="homePhone"
      />

      <CompSiganatureBUs
        v-if="assingBUs && !assingUSA"
        :site="site"
        :link="vlLink"
        :fieldName="fieldName"
        :adress="adress"
        :fieldArea="fieldArea"
        :fieldAdress="fieldAdress"
        :fieldTeams="fieldTeams"
        :cellPhone="cellPhone"
        :homePhone="homePhone"
        :vlText="vlText"
      />

      <CompSignatureUSA
        v-if="assingUSA"
        :bannerImagem="bannerImagem"
        :site="site"
        :link="link"
        :fieldName="fieldName"
        :adress="adress"
        :fieldArea="fieldArea"
        :fieldAdress="fieldAdress"
        :fieldTeams="fieldTeams"
        :cellPhone="cellPhone"
        :homePhone="homePhone"
        :vlText="vlText"
      />
    </div>
    <div class="col-xl-3"></div>
  </div>
</template>

<script>
import CompInputEndereco from "./CompInputEndereco.vue";
import CompInputSite from "./CompInputSite.vue";
import CompCellPhone from "./CompCellPhone.vue";
import CompHomePhone from "./CompHomePhone.vue";
import CompSignature from "./CompSignature.vue";
import UnitsData from "../data/units.json";
import CompSiganatureBUs from "./CompSiganatureBUs.vue";
import CompPhoneGeneric from "./CompPhoneGeneric.vue";
import CompSiteLatam from "./CompSiteLatam.vue";
import CompSignatureUSA from "./CompSignatureUSA.vue";
import VueQrcode from "vue-qrcode";

export default {
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      currentUnit: "Brasil - ICRH",
      units: [],
      site: "",
      cargoArea: "",
      adress: "",
      vlSite: "",
      teams: "",
      form: {},
      maskCellPhone: "55 (##) ####-####",
      maskHomePhone: "55 (##) ####-####",
      vlPlaceholderCellPhone: "+ 55 (00) 90000-0000",
      vlPlaceholderHomePhone: "+ 55 (00) 0000-0000",
      hiddenInput: false,
      genericInput: false,
      bannerImagem: "ass-banner-caixa",
      fieldName: "",
      fieldArea: "",
      fieldAdress: "",
      fieldTeams: "",
      cellPhone: "",
      homePhone: "",
      assingBUs: false,
      assingUSA: false,
      vlText: "",
      link: "",
      isBusLatam: false,
      sitesLatam: [],
      qrCode: "",
      codeCanvas: "",
      phone: "",
      backgroundImage: "/assets/image/background_mobile.png",
      combinedImageUrl: "",
      canvasWidth: 303,
      canvasHeight: 540,
    };
  },
  name: "FormAssinaturaEmail",
  components: {
    VueQrcode,
    CompInputEndereco,
    CompInputSite,
    CompHomePhone,
    CompCellPhone,
    CompSignature,
    CompSiganatureBUs,
    CompPhoneGeneric,
    CompSiteLatam,
    CompSignatureUSA,
  },

  methods: {
    setIninfoMask() {
      this.isBusLatam = this.currentUnit == "BU LATAM" ? true : false;
      this.genericInput = false;
      this.hiddenInput = true;
      switch (this.currentUnit) {
        case "Brasil - Tubos e Conexões":
          this.maskCellPhone = "+ 55 (##) 9 ####-####";
          this.maskHomePhone = "+ 55 (##) ####-####";
          this.vlPlaceholderCellPhone = "+ 55 (00) 9 0000-0000";
          this.vlPlaceholderHomePhone = "+ 55 (00) 0000-0000";
          break;
        case "Brasil - Tigre Metais":
          this.maskCellPhone = "+ 55 (##) 9 ####-####";
          this.maskHomePhone = "+ 55 (##) ####-####";
          this.vlPlaceholderCellPhone = "+ 55 (00) 9 0000-0000";
          this.vlPlaceholderHomePhone = "+ 55 (00) 0000-0000";
          break;
        case "Brasil - Ferramentas para Pintura":
          this.maskCellPhone = "+ 55 (##) 9 ####-####";
          this.maskHomePhone = "+ 55 (##) ####-####";
          this.vlPlaceholderCellPhone = "+ 55 (00) 9 0000-0000";
          this.vlPlaceholderHomePhone = "+ 55 (00) 0000-0000";
          break;
        case "Brasil - Soluções para Água e Efluentes":
          this.maskCellPhone = "+ 55 (##) 9 ####-####";
          this.maskHomePhone = "+ 55 (##) ####-####";
          this.vlPlaceholderCellPhone = "+ 55 (00) 90000-0000";
          this.vlPlaceholderHomePhone = "+ 55 (00) 0000-0000";
          break;
        case "Brasil - ICRH":
          this.maskCellPhone = "+ 55 (##) 9 ####-####";
          this.maskHomePhone = "+ 55 (##) ####-####";
          this.vlPlaceholderCellPhone = "+ 55 (00) 90000-0000";
          this.vlPlaceholderHomePhone = "+ 55 (00) 0000-0000";
          break;
        case "Argentina":
          this.maskCellPhone = "+ 54 9 #### #### ###";
          this.maskHomePhone = "+ 54 #### #### ###";
          this.vlPlaceholderCellPhone = "+ 54 9 00 0000 0000";
          this.vlPlaceholderHomePhone = "+ 54 0000 0000 000";
          break;
        case "Paraguai":
          this.maskCellPhone = "+ 595 ### ### ###";
          this.maskHomePhone = "+ 595 ### ### ###";
          this.vlPlaceholderCellPhone = "+ 595 000 000 000";
          this.vlPlaceholderHomePhone = "+ 595 000 000 000";
          break;
        case "Bolívia":
          this.maskCellPhone = "+ 591 ## ######";
          this.maskHomePhone = "+ 591 ## ######";
          this.vlPlaceholderCellPhone = "+ 591 00 00000000";
          this.vlPlaceholderHomePhone = "+ 591 00000000";

          break;
        case "Peru":
          this.maskCellPhone = "+ 51 #########";
          this.maskHomePhone = "+ 51 1 #######";
          this.vlPlaceholderCellPhone = "+ 51 000000000";
          this.vlPlaceholderHomePhone = "+ 51 0000000";
          break;
        case "Equador":
          this.maskCellPhone = "+ 593 ##########";
          this.maskHomePhone = "+ 593 # #######";
          this.vlPlaceholderCellPhone = "+ 593 0000000000";
          this.vlPlaceholderHomePhone = "+ 593 0 0000000";
          break;
        case "Uruguai":
          this.maskCellPhone = "+ 598 #########";
          this.maskHomePhone = "+ 598 ########";
          this.vlPlaceholderCellPhone = "+ 598 000000000";
          this.vlPlaceholderHomePhone = "+ 598 00000000";
          break;
        case "Chile":
          this.maskCellPhone = "+ 569 ####.####";
          this.maskHomePhone = "+ 5698 ####.####";
          this.vlPlaceholderCellPhone = "+ 569 0000.0000";
          this.vlPlaceholderHomePhone = "+ 569 0000.0000";
          break;
        case "Colombia":
          this.maskCellPhone = "+ 57 ###.####";
          this.maskHomePhone = "+ 571 ###.####";

          this.vlPlaceholderCellPhone = "+ 571 000 000 0000 ";
          this.vlPlaceholderHomePhone = "+ 571 000.0000";
          break;
        case "Corporativo Internacional":
          this.maskCellPhone = "";
          this.maskHomePhone = "";
          this.vlPlaceholderCellPhone = "";
          this.vlPlaceholderHomePhone = "";
          break;
        case "Vendas Internacionais":
          this.maskCellPhone = "+ 55 (##) #####-####";
          this.maskHomePhone = "+ 55 (##) #####-####";
          this.vlPlaceholderCellPhone = "+ 55 (00) 90000-0000";
          this.vlPlaceholderHomePhone = "+ 55 (00) 0000-0000";
          break;
        case "USA":
          this.maskCellPhone = "+ 1 ###.###.####";
          this.maskHomePhone = "+ 1 ###.###.####";
          this.vlPlaceholderCellPhone = "+ 1 000.000.0000";
          this.vlPlaceholderHomePhone = "+ 1 000.000.0000";
          break;
        case "Dura":
          this.maskCellPhone = "+ 1 ###.###.####";
          this.maskHomePhone = "+ 1 ###.###.####";
          this.vlPlaceholderCellPhone = "+ 1 000.000.0000";
          this.vlPlaceholderHomePhone = "+ 1 000.000.0000";
          break;
        case "BU EXPORT":
          this.genericInput = true;
          this.hiddenInput = false;
          this.maskCellPhone = "+ 1 ###.###.####";
          this.maskHomePhone = "+ 1 ###.###.####";
          this.vlPlaceholderCellPhone = "+ 1 000.000.0000";
          this.vlPlaceholderHomePhone = "+ 1 000.000.0000";
          break;
        case "BU USA":
          this.genericInput = true;
          this.hiddenInput = false;
          this.maskCellPhone = "+ 1 ###.###.####";
          this.maskHomePhone = "+ 1 ###.###.####";
          this.vlPlaceholderCellPhone = "+ 1 000.000.0000";
          this.vlPlaceholderHomePhone = "+ 1 000.000.0000";
          break;
        case "BU LATAM":
          this.maskCellPhone = "";
          this.maskHomePhone = "";
          this.vlPlaceholderCellPhone = "";
          this.vlPlaceholderHomePhone = "";
          this.genericInput = true;
          this.hiddenInput = false;
          break;
        case "BU BRASIL":
          this.genericInput = true;
          this.hiddenInput = false;
          this.maskCellPhone = "+ 55 (##) 9 ####-####";
          this.maskHomePhone = "+ 55 (##) ####-####";
          this.vlPlaceholderCellPhone = "+ 55 (00) 9 0000-0000";
          this.vlPlaceholderHomePhone = "+ 55 (00) 0000-0000";
          break;
        default:
          console.log(`Unable to load phone mask`);
      }
    },

    async infoAddress() {
      let adresList = [];
      let vlSite = "";
      let vlLink = "";
      let imageBanner = "";
      const unitSelect = this.currentUnit;
      this.assingBUs = false;
      UnitsData.forEach((e) => {
        if (unitSelect == e.dados.unidade) {
          adresList.push(e.dados.endereco);
          vlSite = e.dados.site;
          vlLink = "https://" + e.dados.site;
          imageBanner = e.dados.imagem_nome;

          if (
            unitSelect == "BU BRASIL" ||
            unitSelect == "BU LATAM" ||
            unitSelect == "BU USA" ||
            unitSelect == "BU EXPORT"
          ) {
            this.assingUSA = unitSelect == "BU USA" ? true : false;
            this.assingBUs = true;
            this.vlText = e.dados.frase;
          }
        }
      });

      this.site = vlSite;
      this.link = vlLink;
      this.adress = adresList;
      this.form.adress =
        adresList && adresList.length === 1 ? adresList[0] : "";
      this.bannerImagem = imageBanner;
    },

    infoUnit() {
      let units = [];
      let infoSites = [];
      let unit = "";
      UnitsData.forEach((e) => {
        if (
          e.nome == "paraguai" ||
          e.nome == "bolivia" ||
          e.nome == "peru" ||
          e.nome == "equador" ||
          e.nome == "uruguai" ||
          e.nome == "chile" ||
          e.nome == "colombia" ||
          e.nome == "argentina"
        ) {
          if (e.nome && unit != e.nome) {
            infoSites.push(e.dados.site);
          }
          unit = e.nome;
        }

        if (
          e.nome == "ICRH" ||
          e.nome == "BU BRASIL" ||
          e.nome == "BU LATAM" ||
          e.nome == "BU USA" ||
          e.nome == "ICRH" ||
          e.nome == "BU EXPORT"
        ) {
          if (e.nome && unit != e.nome) {
            units.push(e.dados.unidade);
          }
          unit = e.nome;
        }
      });

      this.units = units;
      this.sitesLatam = infoSites;
      this.setIninfoMask();
    },

    updateData() {
      this.site = "";
      this.sitesLatam = "";
      this.fieldName = "";
      this.adress = "";
      this.fieldArea = "";
      this.fieldAdress = "";
      this.fieldTeams = "";
      this.cellPhone = "";
      this.homePhone = "";
      this.infoUnit();
      this.infoAddress();
      this.isBusLatam = this.currentUnit == "BU LATAM" ? true : false;
    },
    updateFieldsName(event) {
      this.fieldName = event.currentTarget.value;
    },
    updateFieldsArea(event) {
      this.fieldArea = event.currentTarget.value;
    },
    updateFieldsTeams(event) {
      this.fieldTeams = event.currentTarget.value;
    },
    updateFieldsCellPhone(event) {
      this.cellPhone = event;
    },
    updateFieldsHomePhone(event) {
      this.homePhone = event;
    },
    updateFieldsPhoneGeneric(event) {
      this.homePhone = event;
    },
    formatePhone() {
      this.phone = this.homePhone.replace(/\D/g, "");
      switch (this.currentUnit) {
        case "Brasil - ICRH":
        case "BU BRASIL":
          if (this.phone.startsWith("55")) {
            return this.phone;
          } else {
            return "55" + this.phone;
          }
        case "BU LATAM":
          if (this.phone.startsWith("54")) {
            return this.phone;
          } else {
            return "54" + this.phone;
          }
      }
    },
    generateQRCode() {
      if (this.currentUnit === "BU USA" || this.currentUnit === "BU EXPORT") {
        this.qrCode = this.homePhone;
      } else {
        this.formatePhone();
        this.qrCode = "https://wa.me/" + this.phone;
      }
    },
    combineImages() {
      this.generateQRCode();
      const canvas = this.$refs.canvas;
      if (!canvas) {
        console.error("Elemento canvas não encontrado");
        return;
      }

      const scaleFactor = 4;
      canvas.width = this.canvasWidth * scaleFactor;
      canvas.height = this.canvasHeight * scaleFactor;
      canvas.style.width = `${this.canvasWidth}px`;
      canvas.style.height = `${this.canvasHeight}px`;

      const context = canvas.getContext("2d");
      if (!context) {
        console.error("Não foi possível obter o contexto 2D do canvas");
        return;
      }

      const backgroundImage = new Image();
      backgroundImage.src = this.backgroundImage;

      backgroundImage.onload = () => {
        context.drawImage(backgroundImage, 0, 0, canvas.width, canvas.height);

        const qrcodeImg = document.getElementsByName("qrcode-canvas")[0];

        const x = (canvas.width - 100 * scaleFactor) / 2;
        const y = canvas.height - 170 * scaleFactor;

        context.font = `${20 * scaleFactor}px Arial`;
        context.fillStyle = "white";
        context.textAlign = "center";

        const textX = canvas.width / 2;
        const nameY = y - 80 * scaleFactor;
        const areaY = y - 55 * scaleFactor;

        context.fillText(this.fieldName, textX, nameY);
        context.fillText(this.fieldArea, textX, areaY);

        context.drawImage(
          qrcodeImg,
          x,
          y,
          100 * scaleFactor,
          100 * scaleFactor
        );

        setTimeout(() => {
          this.gerarHTML();
          setTimeout(() => {
            this.downloadQR();
          }, 1000);
        }, 1000);
      };
    },
    downloadQR() {
      const canvas = this.$refs.canvas;
      const url = canvas.toDataURL("image/png");

      const link = document.createElement("a");
      link.href = url;
      link.download = "qrcode_whatsapp.png";
      document.body.appendChild(link);
      setTimeout(() => {
        link.click();
        document.body.removeChild(link);
      }, 100);
    },
    gerarHTML() {
      if (this.fieldName) {
        let paramHTML = this.assingBUs
          ? document.getElementById("signatureBUs").innerHTML
          : document.getElementById("signature").innerHTML;
        let html =
          "<html>" +
          "<head>" +
          "<meta http-equiv='Content-Type' content='text/html' charset='utf-8'> " +
          "</head>" +
          "<body>" +
          paramHTML +
          "</body>" +
          "</html>";
        let base64doc = btoa(unescape(encodeURIComponent(html))),
          a = document.createElement("a"),
          e = new MouseEvent("click");

        a.download = "signature.html";
        a.href = "data:text/htm;base64," + base64doc;
        
        document.body.appendChild(a);

        setTimeout(() => {
          a.click();
          document.body.removeChild(a);
        }, 100);
      }
    },
    onChageAdress(param) {
      if (param) {
        this.form.adress = param;
        this.fieldAdress = param;
      }
    },
    setMaskSiteBUs() {
      switch (this.site) {
        case "www.tigre.com.ar":
          this.maskCellPhone = "+ 54 9 #### #### ###";
          this.maskHomePhone = "+ 54 #### #### ###";
          this.vlPlaceholderCellPhone = "+ 54 9 00 0000 0000";
          this.vlPlaceholderHomePhone = "+ 54 0000 0000 000";
          break;
        case "www.tigre.com.py":
          this.maskCellPhone = "+ 595 ### ### ###";
          this.maskHomePhone = "+ 595 ### ### ###";
          this.vlPlaceholderCellPhone = "+ 595 000 000 000";
          this.vlPlaceholderHomePhone = "+ 595 000 000 000";
          break;
        case "www.tigre.com.bo":
          this.maskCellPhone = "+ 591 ## ######";
          this.maskHomePhone = "+ 591 ## ######";
          this.vlPlaceholderCellPhone = "+ 591 00 00000000";
          this.vlPlaceholderHomePhone = "+ 591 00000000";
          break;
        case "www.tigre.com.ec":
          this.maskCellPhone = "+ 593 ##########";
          this.maskHomePhone = "+ 593 # ########";
          this.vlPlaceholderCellPhone = "+ 593 0000000000";
          this.vlPlaceholderHomePhone = "+ 593 0 00000000";
          break;
        case "www.tigre.pe":
          this.maskCellPhone = "+ 51 ###########";
          this.maskHomePhone = "+ 51 1 #########";
          this.vlPlaceholderCellPhone = "+ 51 00000000000";
          this.vlPlaceholderHomePhone = "+ 51 00000000000";
          break;
        case "www.tigre.com.uy":
          this.maskCellPhone = "+ 598 #########";
          this.maskHomePhone = "+ 598 ########";
          this.vlPlaceholderCellPhone = "+ 598 000000000";
          this.vlPlaceholderHomePhone = "+ 598 00000000";
          break;
        case "www.tigre.cl":
          this.maskCellPhone = "+ 569 #### ####";
          this.maskHomePhone = "+ 569 #### ####";
          this.vlPlaceholderCellPhone = "+ 569 0000 0000";
          this.vlPlaceholderHomePhone = "+ 569 0000 0000";
          break;
        case "www.tigre.co":
          this.maskCellPhone = "+ 57 ###.####";
          this.maskHomePhone = "+ 571 ###.####";
          this.vlPlaceholderCellPhone = "+ 57 000 000 0000 ";
          this.vlPlaceholderHomePhone = "+ 571 000.0000";
          break;

        default:
          console.log("Sorry, we are out of");
      }
    },
    onChageSite(param) {
      if (param) {
        this.site = param;
        this.setMaskSiteBUs();
      }
    },
  },
  created() {
    this.updateData();
  },
  mounted() {
    this.combineImages();
  },
};
</script>
