<template>
  <HeaderForm v-if="authenticated"/>
</template> 

<script>
  import "bootstrap/dist/css/bootstrap.min.css";
  import "./assets/css/global.css";
  import HeaderForm from './components/HeaderForm.vue';
  import { AUTHENTICATED_EVENT } from "@/plugins/azure_login";
  
  export default {
    name: 'App',
    components: {
      HeaderForm
    },
    data() {
      return {
        authenticated: false
      }
    },
    beforeCreate() {
      this.$emitter.on(AUTHENTICATED_EVENT, () => {
        this.authenticated = true
      })
    }
  }
</script>