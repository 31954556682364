<template>
    <div>
        <label for="cellHomePhone" class="form-label">TELEFONE / TELÉFONO / PHONE :</label>
        <input v-if="maskVl" v-mask="maskVl" type="text" class="form-control" id="cellHomePhone" v-model="phone" :placeholder="vlPhone" style="margin-top: 20px;">
        <input v-else type="text" class="form-control" id="cellHomePhone" v-model="phone" :placeholder="vlPhone" style="margin-top: 20px;">
    </div>   
</template>

<script>
    import {mask} from 'vue-the-mask'

export default {

    props: ['valuePhoneGeneric', 'maskHomePhone', 'vlPlaceholderHomePhone', 'hiddenInput', 'genericInput', 'currentUnit'],
    name: 'CompPhoneGeneric',
    directives: {mask},

    data() {
        return {                
            vlPhone: '',
            maskVl: '',
            phone: '',
        };
    }, 

    mounted() {
        this.setMasks();
    },       

    methods: {
        setMasks() {
            this.phone = '';
            this.maskVl = this.currentUnit == 'BU BRASIL' ? "55 (##) 9 ####-####" : this.maskHomePhone;
            this.vlPhone = this.currentUnit == 'BU BRASIL' ? "+ 55 (00) 9 0000-0000" : this.vlPlaceholderHomePhone;
        }
    },      
    watch:{
        phone() { 
            this.$emit('onUpdatePhoneGeneric', this.phone);
        },
        maskHomePhone() {
            this.setMasks(); 
        }
    }
}
</script>