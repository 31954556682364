<template>
    <label for="site" class="form-label">SITE:</label>
    <a class='remove-sub-link' :href="vlLink" target="_blank">
        <input type="text" class="form-control" id="site" disabled v-model="vlSite">
    </a>
</template>

<script>
    export default {       
        name: 'CompInputSite',
        props: ['site', 'link'],
        computed: {
            vlSite: {
                get() {
                    return this.site == "https://www.tigre.com.br/en/export" ? "https://www.tigre.com.br" : this.site 
                }
            },
            vlLink: {
                get() {
                    return this.link == "https://www.tigre.com.br/en/export" ? "https://www.tigre.com.br" : this.link;  
                }
            }
        },
    }
</script>