<template>
  <div id="signature" style="padding-top: 15px">
    <component :is="'style'">
      @import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&display=swap');
    </component>
    <table
      id="signature"
      class="signature"
      width="465"
      cellpadding="0"
      cellspacing="0"
      border="0"
    >
      <tbody>
        <tr>
          <!-- Imagem Cabecalho -->
          <td>
            <a
              class="url-cabecalho"
              :href="`${link}?utm_source=assinatura-emailbrazil-tubos-conexoes&amp;utm_medium=email-brazil-tubos-conexoes&amp;utm_content=assinatura-email-brazil-tubos-conexoes&amp;utm_campaign=assinatura-email`"
              title="Saiba mais."
              target="_blank"
            >
              <img
                id="src_cabecalho"
                width="465"
                height="148"
                class="assinatura-cabecalho"
                align="absmiddle"
                border="0"
                style="display: block"
                alt="Pra que arriscar? Use TIGRE."
                :src="image"/>
            </a>
          </td>
        </tr>
        <tr>
          <td>
            <table width="465" cellpadding="0" cellspacing="0" border="0">
              <tbody>
                <tr>
                  <td height="10"></td>
                </tr>
                <tr align="left">
                  <td width="130" valign="top" class="cargo-wrapper">
                    <span class="nome-area-wrapper">
                      <strong>
                        <span id="assinatura-nome" style="font-size: 12px;   font-family: Ubuntu, Verdana, Arial, sans-serif;  color: #1f3c71;"
                          > {{ !this.name ? vlName : this.name }}
                        </span> </strong
                      ><br />
                      <span style="font-size: 10px; font-family: Ubuntu, Verdana, Arial, sans-serif;  color: #1f3c71;" id="assinatura-area-cargo">
                        {{ !this.cargoArea ? vlAreaCargo : this.cargoArea }}
                      </span>
                    </span>
                  </td>
                  <td width="130" valign="top">
                    <span
                      style="
                        font-size: 12px;
                        font-family: Ubuntu, Verdana, Arial, sans-serif;
                        color: #1f3c71;
                      "
                    >
                      <strong style="font-size: 12px;
                        font-family: Ubuntu, Verdana, Arial, sans-serif;
                        color: #1f3c71;">
                        <span id="assinatura-celular"> {{ cellPhone }} </span> </strong
                      ><br />
                    </span>
                    <span>
                      <strong class="celular-wrapper" v-if="fieldHomePhone">
                        <span id="assinatura-tel-fixo" style="
                        font-size: 12px;
                        font-family: Ubuntu, Verdana, Arial, sans-serif;
                        color: #1f3c71;
                      "> {{ fieldHomePhone  }} </span
                        > <br />
                      </strong>
                      <a
                        href="http://www.tigre.com.br"
                        title="Tigre"
                        id="assinatura-site"
                        target="_blank"
                        style="text-decoration: underline; color: #1f3c71; font-size: 12px;  font-family: Ubuntu, Verdana, Arial, sans-serif;"
                        > {{ this.site }} </a
                      >
                    </span>
                  </td>
                  <td width="15"></td>
                  <td valign="top" width="150">
                    <span
                      style="
                         font-family: Ubuntu, Verdana, Arial, sans-serif;
                         font-size: 11px;
                        color: rgb(31, 60, 113);
                      "
                      class="skype-wrapper" v-if="teams"
                    >
                      {{ !this.teams ? this.vlTeams : "Teams "+ teams }} <br />
                    </span>
                    <span
                      style="                       
                        font-size: 11px;
                        font-family: Ubuntu, Verdana, Arial, sans-serif;
                        color: #1f3c71;
                      ">
                      {{ !this.adressTigre ? this.vlEndereco : this.adressTigre }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td height="10"></td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <!-- Fim informações usuário -->
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  data() {
    return {
      imagemBanner: "",
      vlName: "Nome e Sobrenome.",
      vlAreaCargo: "Nome da área ou Cargo.",
      vlEndereco: "	",
      vlTeams: "",
      vlSite: "",
      vlPhone: "",
      vlCellPhone: "",
    };
  },
  name: "CompSignature",
  props: [
    "bannerImagem",
    "site",
    "fieldName",
    "adress",
    "fieldArea",
    "fieldAdress",
    "fieldTeams",
    "homePhone",
    "cellPhone",
    "link",
  ],

  computed: {
    name: {
      get() {
        return this.fieldName;
      },
    },
    image: {
      get() {
        return this.$image_url("signature/" + this.bannerImagem);
      },
    },
    cargoArea: {
      get() {
        return this.fieldArea;
      },
    },
    adressTigre: {
      get() {
        return this.adress[0];
      },
    },
    teams: {
      get() {
        return this.fieldTeams;
      },
    },
    fieldCellPhone: {
      get() {
        return this.cellPhone;
      },
    },
    fieldHomePhone: {
      get() {
        return this.homePhone;
      },
    },
  },
};
</script>
