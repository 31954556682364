import { createStore } from 'vuex';

const store = createStore({
    state() {
        return {
            msalConfig: {
                auth: {
                    clientId: process.env.VUE_APP_AZURE_CLIENT_ID,
                    authority: process.env.VUE_APP_AZURE_AUTHORITY_URL,
                    redirectUri: process.env.VUE_APP_AZURE_REDIRECT_URL,
                },
                cache: {
                    cacheLocation: 'localStorage',
                },
            },
            accessToken: ''
        };
    }
});

export default store;