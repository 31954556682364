<template>
    <div >
        <label for="cellHomePhone" class="form-label">TELEFONE FIXO / TELÉFONO FIJO / PHONE NUMBER:</label>
        <input v-if='maskVl' v-mask="maskVl" type="text" class="form-control" id="cellHomePhone" v-model="phone" :placeholder="vlPhone"> 
        <input v-else type="text" class="form-control" id="cellHomePhone" v-model="phone" :placeholder="vlPhone"> 
    </div>
</template>

<script>
    import {mask} from 'vue-the-mask'

export default {

    props: ['vlPlaceholderHomePhone', 'hiddenInput', 'genericInput', 'valueHomePhone', 'maskHomePhone', ],
    name: 'CompCellPhone',
    directives: {mask},

    data() {
        return {                
            vlPhone: '',
            maskVl: '',
            phone: ''
        };
    }, 

    mounted() {
        this.setMasks();
    },    

    methods: {
        setMasks() {
            this.phone = '';
            this.maskVl = '';
            this.maskVl = this.maskHomePhone;
            this.vlPhone = this.vlPlaceholderHomePhone;
        }
    },      
    watch:{
        phone() { 
            this.$emit('onUpdate', this.phone);
        },
        maskHomePhone() { this.setMasks(); }
    }
}
</script>